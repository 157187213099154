const FacebookIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 1.33337H9.33333C8.44927 1.33337 7.6014 1.68456 6.97633 2.30969C6.35119 2.93481 6 3.78265 6 4.66671V6.66671H4V9.33337H6V14.6667H8.66667V9.33337H10.6667L11.3333 6.66671H8.66667V4.66671C8.66667 4.48989 8.73693 4.32033 8.86193 4.1953C8.98693 4.07028 9.15653 4.00004 9.33333 4.00004H11.3333V1.33337Z"
        stroke="#F4F4F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FacebookIcon;

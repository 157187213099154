import PropTypes from "prop-types";

const Button = ({ children, className, ...other }) => {
  return (
    <button
      className={`bg-[#FFFFFFE5] hover:opacity-70 transition-all ease-linear disabled:opacity-20 font-medium text-second-0 rounded-full px-6 ${className}`}
      {...other}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
};

export default Button;

import clsx from "clsx";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";
import HeaderSocials from "./socials";
import { linkOffset } from "../../../utils/link-offset";

export const links = [
  {
    text: "Home",
    to: "home",
  },
  {
    text: "Our Games",
    to: "ourGames",
  },
  {
    text: "About us",
    to: "aboutUs",
  },
  {
    text: "Contact",
    to: "contact",
  },
];

const HeaderMenu = ({ isOpen, closeNav }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 525px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <div>
      <div
        className={clsx(
          "fixed inset-0 bg-main-0 opacity-50 duration-150 ease-linear",
          {
            "invisible opacity-0": !isOpen,
          }
        )}
        onClick={closeNav}
      ></div>

      <nav
        className={clsx(
          "max-md:fixed max-md:text-center max-md:space-y-5 max-md:top-0 max-md:bottom-0 max-md:w-64  max-md:max-w-sm max-md:py-6 max-md:px-6 max-md:bg-second-0 max-md:shadow-md max-md:shadow-black max-md:overflow-y-auto max-md:duration-150 max-md:ease-linear",
          {
            "max-md:-right-full": !isOpen,
            "max-md:right-0": isOpen,
          }
        )}
      >
        <div className="flex gap-9 max-lg:gap-6 items-center max-md:flex-col">
          {links.map(({ to, text }) => (
            <Link
              to={to}
              key={to}
              activeClass="text-white"
              smooth={true}
              spy
              duration={500}
              offset={linkOffset(to, isTablet)}
              onClick={closeNav}
              className="text-[#A4A4A4] font-medium cursor-pointer hover:text-white transition-colors duration-100 ease-linear"
            >
              {text}
            </Link>
          ))}
        </div>
        {isMobile && <HeaderSocials/>}
      </nav>
    </div>
  );
};

HeaderMenu.propTypes = {
  isOpen: PropTypes.bool,
  closeNav: PropTypes.func,
};

export default HeaderMenu;

import styles from "./loading-screen.module.css";

const LoadingScreen = () => {
  return (
    <div className="fixed left-0 top-0 bg-second-0 w-full h-full z-50 flex items-center justify-center">
      <div className="w-full max-w-56">
        <span className="relative overflow-hidden block h-1 bg-[#272727]">
          <span
            className={`absolute left-0 top-0 bottom-0 bg-first-0 transition-all duration-200 ease-linear ${styles["elem-1"]}`}
          ></span>
          <span
            className={`absolute left-0 top-0 bottom-0 bg-first-0 transition-all duration-200 ease-linear ${styles["elem-2"]}`}
          ></span>
        </span>
      </div>
    </div>
  );
};

export default LoadingScreen;

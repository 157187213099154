import PropTypes from 'prop-types'

const Circle = ({ children, className }) => {
  return (
    <div className={`w-12 h-12 bg-white bg-opacity-10 flex items-center justify-center rounded-full max-lg:p-3 max-lg:w-10 max-lg:h-10 ${className}`}>
      {children}
    </div>
  );
};

Circle.propTypes = {
  className: PropTypes.string
}

export default Circle;

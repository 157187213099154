import { Link } from "react-router-dom";
import { LogoIcon } from "../../assets/icons";

const Logo = () => {
  return (
    <Link to="/" className="w-[149px] h-6 max-lg:w-28 max-lg:h-5 inline-block">
      <LogoIcon />
    </Link>
  );
};

export default Logo;

export const LeftArrowIcon = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1.33325L1.66939 5.21897C1.2842 5.66836 1.2842 6.33148 1.66939 6.78087L5 10.6666"
        stroke="#F4F4F4"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const RightArrowIcon = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.6667L4.33061 6.78103C4.7158 6.33164 4.7158 5.66852 4.33062 5.21913L1 1.33341"
        stroke="#F4F4F4"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
import { useCallback, useState } from "react";
import { Container } from "../../../components/container";
import Logo from "../../../components/logo/logo";
import HeaderMenu from "./menu";
import { useBodyScrollLock } from "../../../hooks/use-body-scroll-lock";
import HeaderBurger from "./burger";
import { useMediaQuery } from "react-responsive";
import HeaderSocials from "./socials";
import HeaderButton from "./button";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [lockScroll, unLockScroll] = useBodyScrollLock();

  const isMobile = useMediaQuery({
    query: "(max-width: 525px)",
  });

  const closeNav = useCallback(() => {
    setIsNavOpen(false);
    unLockScroll();
  }, [unLockScroll]);

  const openNav = useCallback(() => {
    setIsNavOpen(true);
    lockScroll();
  }, [lockScroll]);

  return (
    <header className="fixed w-full left-0 top-0 z-10 bg-main-0">
      <Container>
        <div className="flex items-center  h-24 max-md:h-16 ">
          <HeaderBurger openNav={openNav} />
          <div className="relatve -top-[2px] mr-10 lg:mr-[60px] max-md:mr-7 max-sm:mr-2">
            <Logo />
          </div>
          <HeaderMenu isOpen={isNavOpen} closeNav={closeNav} />
          <div className="ml-auto">
            {!isMobile ? (
              <div className="flex gap-x-5">
                <HeaderSocials />
                <HeaderButton />
              </div>
            ) : (
              <HeaderButton />
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;

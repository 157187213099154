import { useCallback } from "react";
import { useEffect, useState } from "react";

export const useBodyScrollLock = () => {
  const bodyStyles = typeof window !== "undefined" ? document.body.style : null;
  const [isLock, setIsLock] = useState(bodyStyles?.overflowY === "hidden");

  useEffect(() => {
    bodyStyles.overflowY = isLock ? "hidden" : "visible";
  }, [isLock, bodyStyles]);

  const lock = useCallback(() => {
    setIsLock(true);
  }, [setIsLock]);

  const unLock = useCallback(() => {
    setIsLock(false);
  }, [setIsLock]);
  return [lock, unLock];
};

const BallonsIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="2.66667"
        cy="8.66667"
        rx="2.66667"
        ry="2.66667"
        fill="white"
        fillOpacity="0.95"
      />
      <circle
        cx="9.33332"
        cy="8.66667"
        r="2.66667"
        fill="white"
        fillOpacity="0.95"
      />
      <circle
        cx="6.00001"
        cy="2.66667"
        r="2.66667"
        fill="white"
        fillOpacity="0.95"
      />
    </svg>
  );
};

export default BallonsIcon;

const CaseIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33334 7.50015C1.33334 6.39558 2.22877 5.50015 3.33334 5.50015H22.6667C23.7712 5.50015 24.6667 6.39558 24.6667 7.50015V10.0121C24.6667 10.8953 24.0873 11.6739 23.2414 11.9277L14.1494 14.6553C13.3996 14.8802 12.6004 14.8802 11.8506 14.6553L2.75865 11.9277C1.91268 11.6739 1.33334 10.8953 1.33334 10.0121V7.50015Z"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
      <path
        d="M13 11.5003L13 9.50034"
        stroke="#F4F4F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.3056 11.4998L2.3056 18.4998C2.3056 20.7089 4.09646 22.4998 6.3056 22.4998H19.6945C21.9036 22.4998 23.6945 20.7089 23.6945 18.4998V11.4998"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
      <path
        d="M16.8889 5.49998V3.5C16.8889 2.39543 15.9935 1.5 14.8889 1.5H11.1111C10.0065 1.5 9.11111 2.39543 9.11111 3.5L9.11111 5.49998"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CaseIcon;

const HeartIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44181 7.80898L5.82375 11.3732C7.00673 12.6199 8.99323 12.6199 10.1762 11.3732L13.5581 7.80898C15.0361 6.25133 15.0361 3.72588 13.5581 2.16824C12.0801 0.610588 9.68384 0.610589 8.20584 2.16824V2.16824C8.09393 2.28617 7.90602 2.28617 7.79412 2.16824V2.16824C6.31612 0.610589 3.91981 0.610588 2.44181 2.16824C0.963813 3.72588 0.963814 6.25133 2.44181 7.80898Z"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default HeartIcon;

const StarIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9718 1.87513C12.4382 1.10015 13.5618 1.10015 14.0282 1.87513L16.9621 6.75028C17.1296 7.02869 17.4029 7.22725 17.7195 7.30057L23.2627 8.58438C24.1438 8.78846 24.491 9.85703 23.8981 10.5401L20.1682 14.8369C19.9552 15.0823 19.8508 15.4036 19.8789 15.7273L20.3708 21.3959C20.449 22.297 19.5401 22.9574 18.7072 22.6046L13.4681 20.385C13.1689 20.2582 12.8311 20.2582 12.5319 20.385L7.29277 22.6046C6.45993 22.9574 5.55095 22.297 5.62916 21.3959L6.12112 15.7273C6.14921 15.4036 6.04482 15.0823 5.83181 14.8369L2.1019 10.5401C1.50897 9.85703 1.85616 8.78846 2.73734 8.58438L8.28051 7.30057C8.59707 7.22726 8.87037 7.02869 9.03792 6.75028L11.9718 1.87513Z"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default StarIcon

import Logo from "../../../components/logo/logo";

const FooterTop = () => {
  return (
    <div className="flex sm:items-center justify-between max-sm:flex-col max-sm:gap-y-5">
      <Logo />
      <div className="text-lg text-[#BEBEBE] max-w-80">
        Our products undergo strict quality control to ensure the smooth
        operation of your casinos.
      </div>
    </div>
  );
};

export default FooterTop;

import { Circle } from "../../../components/circle";
import { FacebookIcon, TwitterIcon } from "../../../assets/icons";
import { Link } from "react-router-dom";

const HeaderSocials = () => {
  return (
    <div className="flex gap-x-3 max-lg:gap-x-2 max-sm:justify-center">
      <Link>
        <Circle className="hover:bg-opacity-25 transition-all ease-linear">
          <FacebookIcon />
        </Circle>
      </Link>
      <Link>
        <Circle className="hover:bg-opacity-25 transition-all ease-linear">
          <TwitterIcon />
        </Circle>
      </Link>
    </div>
  );
};

export default HeaderSocials;

import { Link } from "react-scroll";
import { Button } from "../../../components/button";
import { linkOffset } from "../../../utils/link-offset";
import { useMediaQuery } from "react-responsive";

const HeaderButton = () => {
    const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <Link className="sm:flex-1" to="contact" offset={linkOffset("", isTablet)} smooth={true} spy duration={500}>
      <Button className="h-full max-sm:h-9 max-sm:px-3">Get in touch</Button>
    </Link>
  );
};

export default HeaderButton;

const LightningIcon = () => {
  return (
    <svg
      width="18"
      height="26"
      viewBox="0 0 18 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0846 11.158C10.9741 11.158 10.8846 11.0684 10.8846 10.958V1.93718C10.8846 1.74459 10.6391 1.66328 10.5241 1.81779L1.42742 14.0436C1.18199 14.3734 1.41742 14.842 1.82857 14.842H6.91538C7.02583 14.842 7.11538 14.9316 7.11538 15.042V24.0628C7.11538 24.2554 7.36087 24.3367 7.47583 24.1822L16.5725 11.9565C16.818 11.6266 16.5825 11.158 16.1714 11.158H11.0846Z"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LightningIcon;

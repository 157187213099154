import { Outlet } from "react-router-dom";
import { Header } from "./modules/header";
import { Wrapper } from "./modules/wrapper";
import { Footer } from "./modules/footer";
import { Suspense } from "react";
import { LoadingScreen } from "../components/loading";

const Layout = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Wrapper>
        <Header />
        <main className="mt-24 max-md:mt-16 flex-1">
          <Outlet />
        </main>
        <Footer />
      </Wrapper>
    </Suspense>
  );
};

export default Layout;

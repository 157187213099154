const LockIcon = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.66669"
        y="8.5"
        width="18.6667"
        height="14"
        rx="4"
        stroke="#F4F4F4"
        strokeWidth="2"
      />
      <path
        d="M11 16.6667L11 14.3333"
        stroke="#F4F4F4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6667 8.5V5.5C15.6667 3.29086 13.8758 1.5 11.6667 1.5H10.3334C8.12423 1.5 6.33337 3.29086 6.33337 5.5L6.33337 8.5"
        stroke="#F4F4F4"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LockIcon;

import PropTypes from "prop-types";
import { BurgerIcon } from "../../../assets/icons";

const HeaderBurger = ({openNav}) => {
  return (
    <button className="md:hidden p-2 -mt-1 -ml-2 mr-4" onClick={openNav}>
     <BurgerIcon/>
    </button>
  );
};

HeaderBurger.propTypes = {
    openNav:PropTypes.func
};

export default HeaderBurger;

import { Link } from "react-scroll";
import { links } from "../header/menu";
import { useMediaQuery } from "react-responsive";
import { linkOffset } from "../../../utils/link-offset";

const FooterBottom = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });


  return (
    <div className="flex sm:items-center justify-between max-sm:flex-col max-sm:gap-y-6">
      <nav className="flex gap-x-9 max-lg:gap-x-6 sm:items-center max-sm:flex-col max-sm:gap-y-4">
        {links.map(({ to, text }) => (
          <Link
            to={to}
            key={to}
            activeClass="text-white"
            smooth={true}
            spy
            duration={500}
            offset={linkOffset(to, isTablet)}
            className="text-[#A4A4A4] font-medium cursor-pointer hover:text-white transition-colors duration-150 ease-linear"
          >
            {text}
          </Link>
        ))}
      </nav>
      <div className="text-[#BEBEBE] font-medium">hello@r-evolution.org</div>
    </div>
  );
};

export default FooterBottom;

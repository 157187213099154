const MailIcon = () => {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.833252"
        y="1.16669"
        width="23.3333"
        height="18.6667"
        rx="5"
        stroke="#F4F4F4"
        strokeWidth="1.5"
      />
      <path
        d="M5.5 7L11.3 11.35C12.0111 11.8833 12.9889 11.8833 13.7 11.35L19.5 7"
        stroke="#F4F4F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailIcon;

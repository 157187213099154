import { Container } from "../../../components/container";
import FooterBottom from "./bottom";
import FooterTop from "./top";

const Footer = () => {
  return (
    <div className="mt-[120px] max-xl:mt-24 max-lg:mt-20 max-md:mt-16 max-sm:mt-14 bg-[#272727] pt-[47px] pb-[51px] max-xl:pt-9 max-xl:pb-9 max-sm:pt-7 max-sm:pb-7 rounded-t-[20px]">
        <Container>
            <FooterTop/>
            <div className="border-t-[1px] border-t-[#FFFFFF33] mt-5 pt-[31px]">
                <FooterBottom/>
            </div>
        </Container>
    </div>
  );
};

export default Footer;
